//== Ankerlink
:target {
  position: relative;
  top: -120px;
  padding-top: 120px;
  margin-bottom: -120px;
}


//== Überschriften
h1 {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-xxxxlarge;
  font-weight: 300;
}

h2 {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-xxxxxlarge;
  font-weight: 700;
}

.main--box-right {
  h2 {
    font-family: $font-family;
    font-size: $font-basis-px-xlarge;
    font-weight: 300;
  }

  .main--coverage-subtitle {
    font-size: $font-basis-px-xxxxxlarge;
    font-weight: 700;
  }
}

h3 {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-large;
  font-weight: 300;
}

h4 {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-xmedium;
  font-weight: 300;
  margin: 0 0 15px;
  padding-top: 0;
}


//= Absatz
p {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-medium;
  font-weight: 300;
  hyphens: auto;
}

//= Link
a {
  color: $huelsmann-red;
  font-family: $font-family;
  font-weight: 300;
  text-decoration: none;
  transition: $transition-default;
}
button::before {
  transition: $transition-default;
}

// = Listen
li,
.ce-menu li:not {
  color: $font-color-dark;
  font-family: $font-family;
  font-size: $font-basis-px-medium;
  font-weight: 300;
  padding: 10px 0 10px 10px;
}

//= Bold
b {
  font-family: $font-family;
  font-weight: 600;
}

//= Form
input,
textarea {
  font-family: $font-family;
  font-weight: 300;
}

// mobile phone
@media only screen and (max-width: 375px) {
  h2,
  h3,
  h4,
  p,
  a {
    clear: both;
  }

  h1 {
    font-size: $font-basis-px-xlarge;
  }

  h2 {
    font-size: $font-basis-px-xxlarge;
  }
}

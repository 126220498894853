//== Hauptnavigation
.main-nav {
  li {
    list-style: none;
    padding: 0;
  }

  a {
    color: $font-color-medium;
    font-size: $font-basis-px-xxlarge;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $primary-color;
    }
  }
}

//== Scroll-Navigation
.scroll-nav {
  background-color: $huelsmann-white;
  box-shadow: $navigation-boxshadow;
  min-height: 65px;
  margin-left: -350px;
  max-width: 1900px;
  position: fixed;
  transition: $transition-nav;
  top: -50%;
  width: 100%;
  z-index: 5;

  li {
    float: left;
    font-size: $font-basis-px-xlarge;
    line-height: 55px;
    list-style: none;
    margin-right: 25px;
    margin-top: -8px;
    text-transform: uppercase;

    &:nth-last-child(2) {
      width: 30%;
    }

    &:last-child {
      background: $navigation-rectangle;
      background-size: auto 108px;
      clip-path: polygon(248px 380px, 4px 67px, 24px 0px, 248px 0px);
      float: right;
      margin: -13px 0 0;
      padding: 5px 0;

      a {
        color: $font-color-light;
        padding: 15px 35px 15px 60px;
      }
    }
  }

  a {
    color: $font-color-medium;

    &:hover {
      color: $primary-color;
    }
  }

  &--logo {
    height: 50px;
    margin-top: -9px;
    width: 50px;
  }

  &--button {
    &::after {
      content: '>';
      margin-left: 15px;
    }
  }
}


//== Footernavigation
.footer-nav {
  ul {
    padding: 0;
  }

  li {
    list-style: none;
    padding: 0;
  }

  a {
    color: $font-color-medium;
    font-size: $font-basis-px-xxlarge;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $font-color-light;
    }
  }
}

.hamburger-button {
  display: none;
}

// Laptop
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .scroll-nav {
    margin-left: -71px;
  }
}

@media only screen and (max-width: 1355px) {
  .scroll-nav {
    li:nth-last-child(2) {
      width: 20%;
    }
  }
}

@media only screen and (max-width: 1159px) {
  //== Scroll-Navigation
  .scroll-nav {
    li {
      margin-bottom: 5px;
      margin-right: 1.12rem;

      &:nth-last-child(2) {
        clear: left;
        float: left;
        margin-left: 70px;
      }
    }
  }
}

@media only screen and (max-width: 1159px) {
  .scroll-nav {
    li:nth-last-child(2) {
      display: none;
    }
  }
}

// mobile phone
@media only screen and (max-width: 375px) {
  //== Scroll-Navigation
  .scroll-nav {
    display: none;
  }

  //== Menü-Button
  .hamburger-button {
    background: $navigation-mobil-button;
    background-size: contain;
    display: inline;
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 110px;
    z-index: 5;
  }

  //== Hauptnavigation
  .main-nav {
    background-color: $huelsmann-darkgrey;
    left: -375px;
    padding: 25px;
    position: absolute;
    top: 0;
    width: 375px;

    li {
      list-style: none;
      padding: 0;
    }

    a {
      color: $font-color-medium;
      font-size: $font-basis-px-xlarge;
      line-height: 55px;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

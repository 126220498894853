.shariff {
  margin: 4rem 0 5rem;
  padding-bottom: 2rem;

  button {
    height: 29px;
    margin: 30px 15px 40px 0;
    width: 29px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    float: left;
    margin-right: .8rem;
  }

  .share_text {
    display: none;
  }

  .fa {
    &::after {
      content: '';
      padding-left: 2.5rem;
      padding-top: 1rem;
      transition: $transition-default;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 40px 80px;
    }
    &:hover::after {
      background-position: 0 -40px;
    }
  }

  .fa-google-plus {
    &::after {
      background-image: $social-media-googleplus;
    }
  }

  .fa-facebook {
    &::after {
      background-image: $social-media-facebook;
    }
  }

  .fa-xing {
    &::after {
      background-image: $social-media-xing;
    }
  }

  .fa-linkedin {
    &::after {
      background-image: $social-media-linkedin;
    }
  }

  .fa-twitter {
    &::after {
      background-image: $social-media-twitter;
    }
  }
}

// mobile phone
@media only screen and (max-width: 640px) {
  .shariff {
    margin: 3rem 0 2rem;
  }
}

body {
  background: $background-image;
}

.page-wrapper {
  background-color: $huelsmann-white;
  margin: auto;
  width: 1900px;
}

.row {
  min-width: 1140px;
  width: 100%;
}

.header--section,
.main--section,
.footer--section {
  min-width: 1160px;
  width: 100%;
}


//== Header
.header {
  &--section {
    background: $header-background;
    color: $font-color-medium;
    font-size: $font-basis-px-small;
    z-index: 2;

    p {
      clear: both;
    }
  }

  &--box-left {
    height: 1140px;
    padding-right: 125px;
    padding-top: 75px;
    text-align: right;
    width: 724px;
  }

  &--logo {
    height: 80px;
    left: 7em;
    margin-bottom: 150px;
    position: relative;
    width: auto;
  }

  &--bdvi-logo {
    height: auto;
    margin-top: 15px;
    width: 70px;
  }
}


//== Main
.main {
  &--box-left {
    border-right: 1px solid $huelsmann-blue;
    margin-top: 40px;
    text-align: right;
    width: 315px;

    p {
      margin: 0 0 10px;
      padding: 0;
    }
  }

  &--box-left-mobil {
    display: none;
  }

  &--box-right {
    margin-left: 85px;
    margin-top: -570px;
  }

  &--box-top {
    margin-top: -570px;
    margin-bottom: -610px;
  }

  &--box-bottom {
    margin-top: -185px;
  }

  &--coverage {
    &-link {
      color: $huelsmann-red;
      text-decoration: none;

      div {
        background: $coverage-button-background;
        height: 65px;
        left: 1250px;
        padding-top: 23px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        top: -1076px;
        width: 270px;
        z-index: 2;
      }
    }
  }

  &--section-index + .footer--section {
    margin-top: -67px;
  }
}


//== Footer
.footer {
  &--section {
    background: $footer-background;
    color: $font-color-medium;
    font-size: $font-basis-px-medium;
    margin-top: 50px;
    min-height: 300px;
    padding-bottom: 8rem;
    padding-top: 12%;

    .mobil {
      display: none;
    }
  }

  &--address {
    color: $font-color-light;
    font-size: $font-basis-px-medium;

    a {
      color: $font-color-light;
      text-decoration: underline;
    }
  }

  &--copyright {
    color: $font-color-medium;
    font-size: $font-basis-px-small;
    margin-top: 3em;
  }

  &--contact {
    h2,
    p {
      color: $font-color-light;
      font-style: italic;
      font-weight: 300;
    }

    h2 {
      font-size: $font-basis-px-xxlarge;
    }
  }
}


// Laptop
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .page-wrapper {
    width: 1440px;
  }

  .row {
    min-width: 1300px;
    width: 100%;
  }

  .header--section,
  .main--section,
  .footer--section {
    background-position: top center;
    background-size: 1920px;
    min-width: 1440px;
    width: 100%;
  }

  //== Header
  .header {
    &--box-left {
      height: 1140px;
      padding-right: 135px;
      width: 494px;
    }
  }

  .main {
    &--coverage {
      &-link div {
        left: 870px;
        top: -1085px;
      }
    }

    &--box-right {
      margin-left: 117px;
      margin-top: -690px;
    }

    &--box-bottom {
      margin-top: -280px;
    }

    &--section-index + .footer--section {
      margin-top: -141px;
    }
  }
}

// mobile phone
@media only screen and (max-width: 375px) {
  .page-wrapper {
    margin: 0 auto;
    overflow: hidden;
    width: 375px;
  }

  .row {
    width: 375px;
  }

  .header--section,
  .main--section,
  .footer--section {
    max-width: 375px;
    min-width: 375px;
    padding: 20px;
    width: 375px;
  }

  //== Header
  .header {
    &--section {
      background: $background-image;
      clip-path: polygon(375px 320px, 0 280px, 0 0, 375px 0);
      margin-bottom: -90px;
    }

    &--box-left {
      height: 280px;
      padding: 0;
      text-align: right;
      width: 100%;
    }

    &--logo {
      height: auto;
      margin-bottom: 0;
      position: static;
      width: 240px;
    }

    &--bdvi-logo {
      height: auto;
      margin-top: 15px;
      width: 70px;
    }
  }

  .header--search-form {
    display: none;
  }


  //== Main
  .main {
    &--coverage {
      &-link {
        display: none;
      }

      img {
        left: -15%;
        position: relative;
      }
    }

    &--box-top,
    &--box-bottom,
    &--box-right,
    &--box-left-mobil {
      clear: both;
      float: none !important;
      margin-top: 0;
      width: 335px;
    }

    &--box-top {
      margin-left: 0;
    }

    &--box-bottom {
      margin-top: -150px;
    }

    &--box-right {
      margin-left: 0;
    }

    &--box-left {
      display: none;
    }

    &--box-left-mobil {
      border-top: 1px solid $huelsmann-blue;
      clear: both;
      display: inline;
      float: left !important;
      margin-top: 30px;
      padding-top: 30px;
      text-align: left;
    }

    &--section-index + .footer--section {
      margin-top: 305px;
    }
  }

  .footer--section {
    background: $huelsmann-darkgrey;
    margin-top: 10px;
    padding-bottom: 0;
    padding-top: 100px;

    div {
      width: 335px;

      .footer--contact {
        left: 0;
      }

      .mobile {
        background: $huelsmann-deepgrey;
        clip-path: polygon(375px 1305px, 0 1305px, 0 50px, 375px 20px);
        clear: both;
        display: inherit;
        float: none !important;
        left: 0;
        margin-bottom: -25px;
        margin-left: -20px;
        padding: 90px 30px 25px;
        width: 375px;
      }
    }
  }

  .medium-offset-1 {
    margin-left: 0;
  }
}

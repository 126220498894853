@import 'slick.js/slick/slick.scss';
@import 'slick.js/slick/slick-theme.scss';

.slider,
.slick-slide {
  max-height: 570px;
}

.slick-dots {
  bottom: 55px;
  margin-left: 25px;
  text-align: left;
  transform: rotate(-5deg);
  width: 20%;

  li button::before {
    content: $slick-dots;
  }
}

.main {
  &--coverage {
    clip-path: $header-mask-default;
    float: right;
    height: 567px;
    left: 365px;
    margin-top: -600px;
    max-width: 1180px;
    overflow: hidden;
    position: relative;
    width: 160%;
    z-index: 1;

    &-title,
    &-subtitle {
      color: $font-color-light;
      left: 100px;
      position: absolute;
      text-shadow: 0 0 5px $huelsmann-grey;
    }

    &-title {
      top: 250px;
    }

    &-subtitle {
      display: inline;
      top: 230px;
    }
  }
}


// Laptop
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .slick-dots {
    bottom: 145px;
    margin-left: 25px;
  }

  .main {
    &--coverage {
      &-title {
        top: 180px;
      }

      &-subtitle {
        font-size: $font-basis-px-xxxxxxlarge !important;
        top: 190px;
      }
    }

    &--box-top {
      .main--coverage {
        clip-path: $header-mask-laptop;
        left: 325px;
      }
    }

    &--box-right {
      .main--coverage {
        clip-path: $header-mask-laptop;
        height: 467px;
        left: 108px;
        top: 120px;
        width: 115%;
      }
    }
  }
}

// Tablet
@media only screen and (min-width: 376px) and (max-width: 640px) {
  .main {
    &--coverage {
      &-title {
        max-width: 340px;
        left: 70px;
        top: 165px;
      }

      &-subtitle {
        left: 70px;
        top: 180px;
      }
    }

    &--box-top {
      .main--coverage {
        clip-path: $header-mask-tablet;
        left: 323px;
        top: 2px;
      }
    }

    &--box-right {
      .main--coverage {
        left: 115px;
      }
    }
  }
}

// mobile phone
@media only screen and (max-width: 375px) {
  .main {
    &--coverage {
      clip-path: $header-mask-mobile;
      float: none;
      height: 250px;
      left: -50px;

      &-title {
        max-width: 340px;
        left: 50px;
        top: 75px;
      }

      &-subtitle {
        font-size: $font-basis-px-xxlarge !important;
        left: 50px;
        top: 90px;
      }
    }

    &--box-top {
      .main--coverage {
        margin-top: 0;
      }
    }

    &--box-right {
      .main--coverage {
        margin-top: 10px;
      }
    }
  }
}

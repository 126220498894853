//== Colors
$primary-color: #e2001a;
$secondary-color: #00a2ff;

$huelsmann-white: #fff;
$huelsmann-red: $primary-color;
$huelsmann-darkred: #681721;
$huelsmann-blue: $secondary-color;
$huelsmann-lightgrey: #ececec;
$huelsmann-grey: #524d4d;
$huelsmann-deepgrey: #333;
$huelsmann-darkergrey: #817f7f;
$huelsmann-darkgrey: #3d3a3a;
$huelsmann-violett: #9a4a7e;
$huelsmann-rose: #eb8977;
$huelsmann-dark-rose: #dd707a;

//== Images
$background-image: #ebebeb url('../Images/background-image.jpg') center center repeat;
$background-geo-lines: url('../Images/geo-lines.png');
$header-background: url('../Images/header-background.png') top left no-repeat;
$coverage-button-background: url('../Images/coverage-button.png') top left no-repeat;
$footer-background: url('../Images/footer-background.png') top center no-repeat;

//== Fonts
$font-family: 'Source Sans Pro', sans-serif;

//== Font-Colors
$font-color-light: $huelsmann-white;
$font-color-medium: $huelsmann-darkergrey;
$font-color-dark: $huelsmann-deepgrey;
$font-color-lightdark: $huelsmann-grey;
$font-color-blue: $huelsmann-blue;

//== Font-Size
$font-basis-px-xxxxxxlarge: 85px;
$font-basis-px-xxxxxlarge: 115px;
$font-basis-px-xxxxlarge: 54px;
$font-basis-px-xxlarge: 40px;
$font-basis-px-xlarge: 27px;
$font-basis-px-large: 22px;
$font-basis-px-xmedium: 20px;
$font-basis-px-medium: 18px;
$font-basis-px-small: 14px;

//== Button-Colors
$button-color: $primary-color;
$button-color-hover: #681721;

//== Navigation
$navigation-boxshadow: 0 0 60px -20px rgba(0, 0, 0, .75);
$navigation-rectangle: $button-color url('../Images/navigation-rectangle.svg') center left no-repeat;
$navigation-mobil-button: url('../Images/mobil-nav-button.png') left top no-repeat;

//== Slick
$slick-font-path: '../Fonts/';
$slick-loader-path: '../Images/';
$slick-dots: url('../Images/slick-dots.svg');

//== Filter icon
$filter-street: url('../Images/filter-street.png') center center no-repeat;
$filter-ground: url('../Images/filter-ground.png') center center no-repeat;
$filter-building: url('../Images/filter-building.png') center center no-repeat;
$filter-all: url('../Images/filter-all.png') center center no-repeat;

//== Social Media Icons
$social-media-googleplus: url('../Images/icon-googleplus.png');
$social-media-facebook: url('../Images/icon-facebook.png');
$social-media-xing: url('../Images/icon-xing.png');
$social-media-linkedin: url('../Images/icon-linkedin.png');
$social-media-twitter: url('../Images/icon-twitter.png');

//== Animations
$transition-default: all .2s ease-in;
$transition-nav: all .8s ease;

//== Accordion
$accordion-border: 1px solid $huelsmann-lightgrey;

//== Listen
$list-icon-image: url('../Images/list-icon.png');

//== Tablle
$table-border-style: 1px solid $huelsmann-lightgrey;

//== Button
$button-icon: url('../Images/button-icon.svg');
$button-icon-hover: url('../Images/button-hover-icon.svg');

//= Formular
$input-submit-icon: transparent url('../Images/search-icon.svg') center no-repeat;

//== Trenner
$trenner-border-style: 1px dotted $font-color-dark;

//== Masks
$header-mask-default: polygon(3px 568px, 60px 0px, 1182px 70px, 1182px 440px);
$header-mask-laptop: polygon(0px 463px, 50px 2px, 939px 56px, 939px 358px);
$header-mask-tablet: polygon(45px 0px, 0px 463px, 940px 375px, 940px 53px);
$header-mask-mobile: polygon(0px 0px, 0px 245px, 400px 210px, 400px 60px);

//== Filter-Bar
.ce-filterbar {
  background: $huelsmann-blue $background-geo-lines center top no-repeat;
  clip-path: polygon(1240px 875px, 0 774px, 63px 140px, 1240px 13px);
  height: 875px;
  left: -105px;
  padding: 160px 90px;
  position: relative;
  top: -173px;
  width: 161%;
  z-index: 0;


  h2 {
    color: $font-color-lightdark;
    font-family: $font-family;
    font-size: $font-basis-px-xxlarge;
    font-style: italic;
    font-weight: 300;
  }

  &--button {
    color: $font-color-light;
    font-family: $font-family;
    font-size: $font-basis-px-large;
    font-weight: 600;
    margin-right: 30px;
    opacity: .8;
    outline: 0;
    padding: 20px 0;
    text-transform: uppercase;

    &:hover {
      opacity: 1;
    }

    &::before {
      background-size: 40px;
    }

    &:last-child::before {
      background-size: 100px 50px;
    }

    &-street::before,
    &-ground::before,
    &-building::before {
      content: '';
      padding: 15px 72px 15px 0;
    }

    &-street::before {
      background: $filter-street;
    }

    &-ground::before {
      background: $filter-ground;
    }

    &-building::before {
      background: $filter-building;
    }

    &-all::before {
      background: $filter-all;
      content: '';
        padding: 15px 130px 15px 0;
    }
  }

  &--kachelsection {
    height: 410px !important;
    margin-top: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &--kachel {
    background-color: rgba($huelsmann-grey, .4);
    float: left;
    margin-bottom: 8px;
    margin-left: 8px;
    min-height: 125px;
    padding: 0 20px;
    transition: $transition-default;

    &:hover {
      background-color: $huelsmann-white;

      p {
        color: $font-color-blue;
      }
    }

    p {
      color: $font-color-light;
    }
  }

  .grid-item {
    width: 280px;
  }
}

a .ce-filterbar--kachel {
  &::after {
    bottom: 5px;
    color: $font-color-light;
    content: '»';
    float: right;
    font-size: $font-basis-px-xxlarge;
    right: 16px;
    position: absolute;
  }

  &:hover::after {
    color: $font-color-blue;
  }
}

//== Accordion-Element
.ce-accordion {
  border: 0 !important;
  margin: 2rem 0 1rem;
  padding: 0;

  &--item {
    border: $accordion-border;
    list-style: none;
    margin: 0 0 .3rem;
    padding: 0;
  }

  &--title {
    border: 0 !important;
    color: $font-color-dark !important;
    font-family: $font-family;
    font-size: $font-basis-px-medium !important;
    font-weight: 300;

    &::before {
      font-size: $font-basis-px-medium;
      font-weight: bold;
    }
  }

  &--content {
    color: $font-color-dark;
    font-family: $font-family;
    font-size: $font-basis-px-medium;
    font-weight: 300;
  }

  .is-active {
    .ce-accordion--title::before {
      color: $primary-color;
    }
  }
}

//== Listen
.ce-bullets,
.ce-bodytext {
  li {
    color: $font-color-dark;
    font-family: $font-family;
    font-size: $font-basis-px-medium;
    font-weight: 300;
    list-style: $list-icon-image !important;
    padding: 10px 0 10px 10px;
  }
}

//== Trenner
.ce-div {
  border: 0;
  border-top: $trenner-border-style;
}

//== Tabellen
.ce-table {
  font-family: $font-family;
  font-weight: 300;
  margin: 10px 0;
  width: 100%;

  tr {
    border-bottom: $table-border-style;

    &:first-child {
      background-color: $font-color-dark;

      td {
        color: $font-color-light;
      }
    }
  }

  td {
    color: $font-color-dark;
    font-family: $font-family;
    font-size: $font-basis-px-medium;
    font-weight: 300;
    padding: 5px 10px;
  }
}


//== Button
.ce-button {
  margin-right: 20px;

  span {
    background-color: rgba($huelsmann-grey, .1);
    font-family: $font-family;
    font-size: $font-basis-px-medium;
    font-weight: 300;
    padding: 12px 15px;
    text-transform: uppercase;
    transition: $transition-default;
  }

  i::before {
    content: $button-icon;
    margin-right: 5px;
    position: relative;
    top: 18px;
  }

  &:hover,
  &:active {
    span {
      background-color: rgba($huelsmann-red, .1);
    }

    i::before {
      content: $button-icon-hover;
    }
  }
}


//== Navigation
.ce-menu {
  clear: both;
  list-style: none;
  margin-left: -45px;
  min-height: 120px;

  a {
    border: 1px solid $huelsmann-red;
    border-radius: 3px;
    float: left;
    font-size: $font-basis-px-large;
    margin: 5px;
    padding: 10px;
    transition: all .2s ease-out;

    &:hover {
      background-color: $huelsmann-red;
      color: $font-color-light;
    }
  }
}

// Laptop
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .ce-filterbar {
    clip-path: polygon(1004px 895px, 0px 805px, 66px 139px, 1004px 36px);
    height: 900px;
    left: -83px;
    top: -278px;
    width: 120%;

    &--button {
      font-size: $font-basis-px-medium;
    }

    .grid-item {
      width: 225px;
    }
  }
}

@media only screen and (min-width: 376px) and (max-width: 639px) {
  .ce-filterbar {
    width: 78.5%;
    left: 357px;
  }
}

// mobile phone
@media only screen and (max-width: 375px) {
  .ce-gallery {
    float: none !important;
  }

  .ce-button {
    display: block;
    margin-bottom: 15px;

    i {
      top: 20px;
    }

    span {
      font-size: $font-basis-px-small;
      padding: 16px 15px;
    }
  }

  .ce-div {
    clear: both;
    float: left;
    width: 335px;
  }

  .ce-menu {
    min-height: 420px;
  }

  .ce-media {
    img {
      height: auto;
      width: 320px;
    }
  }

  .ce-filterbar {
    clip-path: none;
    height: auto;
    padding: 200px 90px 20px 90px;
    z-index: 0;

    &--button {
      margin-bottom: 50px;
      padding: 15px 0;
    }

    .grid-item {
      width: 100%;
    }

    h2 {
      display: none;
    }
  }
}

//== Indexed search
#tx_indexedsearch {
  font-family: $font-family;
  font-weight: 300;

  td {
    padding-bottom: 10px;
    padding-right: 20px;
  }

  tr td:first-child() {
    padding-right: 25px;
  }

  select,
  input {
    border: 0;
  }
}

.main--section {
  #tx_indexedsearch {
    fieldset {
      background: $huelsmann-blue $background-geo-lines center top no-repeat;
      border: 0;
      clip-path: $header-mask-default;
      color: $font-color-light;
      float: right;
      height: 577px;
      left: 367px;
      margin-top: -618px;
      max-width: 1180px;
      overflow: hidden;
      padding: 220px 70px 150px 150px;
      position: relative;
      width: 160%;
      z-index: 1;
    }

    label {
      font-size: $font-basis-px-xlarge;
    }


    select,
    input {
      border-bottom: 1px solid $huelsmann-white;
      color: $font-color-light;
    }

    .tx-indexedsearch-searchbox-sword {
      background-color: transparent;
      margin-left: 15px;
      width: 70%;
    }

    .tx-indexedsearch-searchbox-button {
      background-color: $huelsmann-white;
      border: 2px solid $huelsmann-white;
      border-radius: 3px;
      color: $huelsmann-blue;
      font-size: $font-basis-px-xlarge;
      margin-top: 25px;
      padding: 10px 35px;
      transition: $transition-default;

      &:hover {
        background-color: transparent;
        color: $huelsmann-white;
      }
    }
  }

  .tx-indexedsearch-icon,
  .tx-indexedsearch-percent,
  .tx-indexedsearch-text-item-size,
  .tx-indexedsearch-text-item-crdate,
  .tx-indexedsearch-text-item-mtime,
  dt.tx-indexedsearch-text-item-path {
    display: none;
  }

  dd.tx-indexedsearch-text-item-path {
    margin-left: 0;
  }

  .tx-indexedsearch-res {
    border-bottom: $accordion-border;
  }
}


.tx-indexedsearch-searchbox-sword {
  border: 0;
  border-bottom: 1px solid $huelsmann-darkergrey;
  color: $primary-color;
  font-size: $font-basis-px-xlarge;
  outline: 0;
  padding: 5px 0;
  width: 175px;
}


//== Suchformular im Header
.header--search-form {
  margin-bottom: 90px;

  form {
    clear: both;

    fieldset {
      border: 0;
      border-bottom: 1px solid $huelsmann-darkergrey;
      float: right;
      padding: 0;
      width: 210px;
    }

    input {
      background-color: transparent;
      border: 0;
      color: $primary-color;
      height: 40px;
      outline: 0;
      padding: 5px 0;
      text-align: right;
      width: 175px;

      &[type="submit"] {
        background: $input-submit-icon;
        background-size: 85%;
        height: 25px;
        width: 25px;
      }
    }
  }
}

//== Scroll-Navigation
.scroll-nav--search-form form {
  clear: both;

  fieldset {
    border: 0;
    float: right;
    padding: 0;
    width: 90%;

    &:hover,
    &:active {
      border-bottom: 1px solid $huelsmann-darkergrey;
    }
  }

  input {
    background-color: transparent;
    border: 0;
    color: $primary-color;
    height: 40px;
    outline: 0;
    padding: 5px 0;
    text-align: left;
    width: 80%;

    &[type="submit"] {
      background: $input-submit-icon;
      background-size: 85%;
      height: 25px;
      width: 25px;
    }
  }
}


//== Kontaktformular im Footer
.main--box-right {
  .csc-mailform {
    color: $font-color-dark;

    label {
      color: $font-color-dark;
    }

    input,
    textarea {
      width: 100%;
    }

    input[type="submit"] {
      background-color: $huelsmann-darkergrey;
      color: $font-color-light;
    }
  }
}


.csc-mailform {
  color: $font-color-light;
  font-family: $font-family;
  font-style: italic;
  font-weight: 600;

  ol {
    list-style: none;
    margin-left: -40px;
  }

  li {
    font-weight: 300;
    font-style: normal;
  }

  label,
  input,
  textarea {
    font-family: $font-family;
    font-size: $font-basis-px-large;
    font-weight: 300;
  }

  input,
  textarea {
    background-color: transparent;
    border: 1px solid $huelsmann-darkergrey;
    border-radius: 3px;
    height: 55px;
    margin: 10px 0 25px 0;
    outline: 0;
    padding: 15px;
    width: 560px;
  }

  label {
    color: $font-color-light;
    font-weight: 600;
    margin: 15px 0;
  }

  input {
    display: block;

    &[type="submit"] {
      background-color: $huelsmann-white;
      color: $font-color-dark;
      margin-top: 15px;
      transition: $transition-default;
      width: 260px;

      &:hover {
        background-color: $huelsmann-blue;
        border: 1px solid $huelsmann-blue;
        color: $font-color-light;
      }
    }
  }

  textarea {
    height: 135px;
  }
}

.csc-form-4 label{
  display: block;
  margin: 0
}

.csc-form-confirmation-false {
  float: right;

  input {
    background-color: transparent !important;
    color: $font-color-light !important;
  }
}

.csc-form-confirmation-true{
  float: left;
}


// Laptop
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .main--section #tx_indexedsearch fieldset {
    clip-path: $header-mask-laptop;
    height: 467px;
    left: 110px;
    margin-top: -481px;
    width: 115%;
  }
}

// Tablet
@media only screen and (min-width: 376px) and (max-width: 640px) {
  .main--section #tx_indexedsearch fieldset {
    clip-path: $header-mask-tablet;
    left: 118px;
    margin-top: -478px;
  }
}

// mobile phone
@media only screen and (max-width: 375px) {
  .csc-mailform {
    input,
    textarea {
      width: 300px;
    }
  }

  .main--section #tx_indexedsearch {
    fieldset {
      clip-path: polygon(680px 260px, 0 310px, 30px 15px, 680px 60px);
      height: 320px;
      left: 32px;
      margin-top: 0;
      padding: 90px;
      width: 135%;
    }

    .tx-indexedsearch-searchbox-sword {
      width: 100%;
    }
  }
}
